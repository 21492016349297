import devConfig from '../../../config.development.json';
import config from '../../../config.json';
import localConfig from '../../../config.local.json';
import prodConfig from '../../../config.production.json';
import preProdConfig from '../../../config.preproduction.json';
import stagingConfig from '../../../config.staging.json';

const env = import.meta.env;

const getConfig = () => {
  switch (env['VITE_V2_APP_ENV']) {
    case 'local':
      return { ...config, ...localConfig };
    case 'development':
      return { ...config, ...devConfig };
    case 'staging':
      return { ...config, ...stagingConfig };
    case 'preproduction':
      return { ...config, ...preProdConfig };
    case 'production':
      return { ...config, ...prodConfig };
    default:
      return { ...config, ...prodConfig };
  }
};

export default getConfig();
